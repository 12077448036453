.EditableTrackerList {
  min-height: 2.8rem;
  padding: 0;
}

.EditableTrackerList > li {
  display: flex;
  justify-content: space-between;
}

.EditableTrackerList .button span:first-child {
  display: inline-block;
  font-size: 1.4rem;
  height: 2.8rem;
  line-height: 2.8rem;
  width: 2.8rem;
}

.EditableCategoryList__Controls {
  display: flex;
  justify-content: center;
  margin-bottom: 2.8rem;
}

.EditableCategoryList__Controls button.button {
  background-color: var(--ship);
  color: var(--space);
  width: 100%;
}
