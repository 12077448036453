.Loader {
  background-color: var(--space);
  display: flex;
  font-size: 4.8rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 8rem);
}

.Loader span {
  animation: Spin infinite 1s linear;
  height: 8rem;
  width: 8rem;
}
