.ColourPicker {
  width: 100%;
}

.ColourPicker button {
  border: 0;
  height: 2.8rem;
  width: 2.8rem;
}

.ColourPicker ul {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
}

.ColourPicker li.selected button {
  box-shadow: inset 0 0 0 0.1rem var(--space), inset 0 0 0 0.3rem var(--ship),
    inset 0 0 0 0.4rem var(--space);
}
