html {
  box-sizing: border-box;
  font-size: 62.5%;
}

html,
body,
#root {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--space);
  color: var(--ship);
  margin: 0;
  padding: 0;
  font-family: "Overpass Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: var(--ui);
  color: var(--ship);
}

::-moz-selection {
  background: var(--ui);
  color: var(--ship);
}

button {
  border: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

select {
  border-radius: 0;
}

input[type="text"] {
  border-radius: 0;
}

.App {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
}

.App-content {
  min-height: calc(100vh - 9.6rem);
}

.sm-emoji {
  height: 2.8rem;
  width: 2.8rem;
  text-align: center;
}

.sm-emoji span,
.sm-emoji button {
  border: 0;
  font-size: 1.2rem;
  line-height: 3rem;
  text-align: center;
}

.not-dragging .sm-outliner:not(:disabled):hover {
  box-shadow: inset 0 0 0 0.1rem var(--space), inset 0 0 0 0.3rem var(--ui),
    inset 0 0 0 0.4rem var(--space);
}

.not-dragging .sm-outliner:not(:disabled):focus {
  outline: none;
  box-shadow: inset 0 0 0 0.1rem var(--space), inset 0 0 0 0.3rem var(--ui-alt),
    inset 0 0 0 0.4rem var(--space);
}

@media (min-width: 768px) {
  .App {
    height: 100vh;
    height: 100%;
  }

  .App-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    height: calc(100% - 5.6rem);
    overflow: hidden;
  }
}
