.Loading {
  align-items: center;
  background-color: var(--space);
  color: var(--ship);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.Loading h1 {
  font-size: 3rem;
  font-variant: small-caps;
  text-align: center;
}

.Loading > * {
  margin: 2rem;
}

.Loading svg {
  animation: Spin infinite 1s linear;
  height: 10rem;
  vertical-align: middle;
  width: 10rem;
}
