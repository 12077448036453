.TrackerList {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 2.8rem;
}

@media (min-width: 768px) {
  .CategoryList {
    padding-bottom: 2.8rem;
  }
}
