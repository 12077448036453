.Category {
  padding: 0 2.8rem;
}

.Category.Category--filtered .Category__Header {
  opacity: 0.6;
}

.Category__Header {
  background-color: var(--ship);
  border-bottom: 1px solid var(--space);
  color: var(--space);
  display: flex;
  justify-content: space-between;
}

.Category__Header button {
  background-color: var(--ship);
  border: none;
  flex-shrink: 0;
  padding: 0;
  width: 2.8rem;
}

.Category__Info {
  display: flex;
  height: 2.8rem;
  line-height: 2.9rem;
  max-width: calc(100% - 4rem);
}

.Category__Info > button {
  border-right: 0.1rem solid var(--space);
}

.Category__Label {
  font-size: 1.4rem;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  overflow: hidden;
  padding-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Category__Controls {
  display: flex;
  height: 2.8rem;
  line-height: 3rem;
  max-width: calc(100% - 4rem);
}

.Category__Controls > button {
  border-left: 0.1rem solid var(--space);
}

.Category__Controls > button svg {
  transition: transform 300ms ease-in-out;
}

.Category--collapsed .Category__Controls > button svg {
  transform: rotate(180deg);
}

.CategoryList > li:last-child .TrackerList {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .CategoryList > li:last-child .TrackerList {
    padding-bottom: 2.8rem;
  }
}
