.Examples {
  margin: 0 auto;
  max-width: 96rem;
  padding: 5.6rem 2.8rem;
}

.Examples h2 {
  margin-top: 0;
  text-align: center;
}

.Examples > p {
  line-height: 1.5;
  margin: 0 auto 5.6rem;
  max-width: 50rem;
  text-align: center;
}

.Examples__Items ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 2.8rem;
  max-width: 63rem;
  grid-template-rows: minmax(1px, auto) 1fr;
}

.Examples__Items li {
  padding-bottom: 1.4rem;
  text-align: center;
}

.Examples__Items img {
  display: block;
  margin: 0 auto;
}

.Examples__Items h3 {
  margin: 0 0 1.4rem 0;
}

@media (min-width: 464px) and (max-width: 682px) {
  .Examples__Items li:last-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
