.SignUpForm {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.1rem;
  font-size: 1.6rem;
  padding: 2.8rem 2.8rem 3.4rem;
}

.SignUpForm > h2 {
  font-size: 4rem;
  font-variant: small-caps;
  margin: 0 0 0.7rem;
  text-align: center;
}

.SignUpForm > p {
  margin: 0 0 2.8rem;
  text-align: center;
}

.SignUpForm form > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.SignUpForm form > ul > li:not(:last-child) {
  margin-bottom: 2.1rem;
}

.SignUpForm label {
  display: block;
  margin-bottom: 0.7rem;
}

.SignUpForm input {
  background-color: var(--ship);
  border-radius: 0.1rem;
  color: var(--space);
  border: 0;
  height: 3.5rem;
  padding: 0 1rem;
  width: 100%;
}

.SignUpForm .field-error label {
  color: var(--danger);
}

.SignUpForm .field-error input {
  outline: 0.1rem solid var(--danger);
}

.SignUpForm__Error {
  color: var(--danger);
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 0.7rem;
}

.SignUpForm button {
  background-color: var(--ui-dark);
  border: 0;
  border-radius: 0.1rem;
  color: var(--ship);
  font-size: 2rem;
  font-variant: small-caps;
  height: 4.2rem;
  letter-spacing: 0.1rem;
  line-height: 4.3rem;
  margin-top: 1.4rem;
  width: 100%;
}

.SignUpForm button:hover {
  background-color: var(--ui);
}

.SignUpForm__Errors {
  background-color: var(--danger);
  color: var(--ship);
  font-size: 1.4rem;
  margin-top: 2.8rem;
  padding: 1rem 1.4rem;
  line-height: 1.5;
}

.SignUpForm__Errors p {
  margin: 0;
}
