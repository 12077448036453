.SelectedDay > div {
  display: flex;
}

.SelectedDay__Label {
  background-color: var(--ship);
  border-bottom: 1px solid var(--space);
  color: var(--space);
  font-size: 1.4rem;
  height: 2.8rem;
  justify-content: space-between;
  line-height: 2.9rem;
  padding: 0 1rem;
}

.SelectedDay button {
  background-color: var(--ship);
  flex-shrink: 0;
  height: 2.8rem;
  outline: 0;
  width: 2.8rem;
}

.SelectedDay select {
  appearance: none;
  border: none;
  border-right: 1px solid var(--space);
  cursor: pointer;
  height: 2.8rem;
  flex-grow: 1;
  font-size: 1.4rem;
  font-variant: small-caps;
  text-align: center;
  background-color: var(--ship);
  color: var(--space);
  text-align-last: center;
}

.SelectedDay__Controls > select:nth-child(2) {
  border-left: 1px solid var(--space);
}

.SelectedDay select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--space);
}

.SelectedDay option {
  background-color: var(--ship);
  color: var(--space);
}

.SelectedDay option[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

.PointTotal svg {
  position: relative;
  top: -0.2rem;
  margin-right: 0.7rem;
}
