.LoaderMedium {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-variant: small-caps;
}

.LoaderMedium > div:first-child {
  margin-bottom: 1.4rem;
}

.LoaderMedium svg {
  animation: Spin infinite 1s linear;
  width: 5.6rem;
}

.LoaderMedium > div:last-child {
  font-size: 1.6rem;
}
