.Day {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid var(--space);
  border-left: 1px solid var(--space);
  flex-shrink: 0;
  height: 10rem;
  overflow: hidden;
  position: relative;
  width: 10rem;
}

.Day.Day--today {
  background-color: rgba(255, 255, 255, 0.2);
}

.Day:focus {
  background-color: var(--ui-alt);
  outline: none;
}

.Day:not(.Day--future):hover {
  background-color: var(--ui);
  cursor: pointer;
}

.Day.Day--selected {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Future Days */
.Day.Day--future {
  opacity: 0.6;
}

.Day:hover .Day__Date,
.Day:hover .Day__month-label,
.Day:hover .Day__year-label,
.Day.Day--selected .Day__Date,
.Day.Day--selected .Day__month-label,
.Day.Day--selected .Day__year-label {
  opacity: 1;
}

.Day__Date {
  background-color: var(--space);
  bottom: 0;
  color: var(--ship);
  font-size: 1rem;
  height: 2rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  opacity: 0;
  padding-left: 0.3rem;
  position: absolute;
  right: 0;
  text-align: center;
  width: 2rem;
}

.Day__Date abbr {
  text-decoration: none;
}

.Day__month-label,
.Day__year-label {
  background-color: var(--ship);
  bottom: 0;
  color: var(--space);
  display: flex;
  font-size: 1.2rem;
  font-variant: small-caps;
  height: 2rem;
  justify-content: space-between;
  letter-spacing: 0.1rem;
  line-height: 1.8rem;
  padding: 0.2rem;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.Day__month-label span:last-child,
.Day__year-label span:last-child {
  margin-right: 0.2rem;
  text-align: right;
}

.Day__month-label abbr,
.Day__year-label abbr {
  text-decoration: none;
}

.Day__notes-indicator {
  bottom: 0;
  font-size: 1rem;
  position: absolute;
  right: 0;
}

.Day .Items {
  animation: FadeIn 1 500ms linear;
  margin: 0;
  padding: 0;
}

.Day .Items li {
  list-style: none;
}

/* Display Type: Emoji */
.Day.Day--DayEmojis .Item {
  display: block;
  float: left;
  font-size: 1.2rem;
  height: 2rem;
  line-height: 2.2rem;
  text-align: center;
  width: 20%;
}

/* Display Type: Tiny Squares */
.Day.Day--DayTinySquares .Item {
  display: block;
  float: left;
  height: 0.78rem;
  width: 10%;
}

/* Display Type: Squares */
.Day.Day--DaySquares .Item {
  display: block;
  float: left;
  height: 2rem;
  width: 20%;
}

/* Display Type: Points & Total - Number */
.Day .Number {
  animation: FadeIn 1 500ms linear;
  font-size: 2rem;
  font-weight: bold;
  line-height: 10.6rem;
  text-shadow: 1px 0 0 var(--space), -1px 0 0 var(--space), 0 1px 0 var(--space),
    -0 -1px 0 var(--space);
  text-align: center;
}

.Day .Number.Number--medium {
  font-size: 2.4rem;
}

.Day .Number.Number--large {
  font-size: 2.8rem;
}

.Day .Number.Number--huge {
  font-size: 3.2rem;
}

.Day .Number.Number--negative {
  color: #f99;
  font-size: 1.6rem;
}

.Day .Number.Number--positive {
  color: #9f9;
}
