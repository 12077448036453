.DataSets {
  align-items: center;
  display: flex;
}

.DataSets label {
  margin-right: 0.7rem;
}

.DataSets select {
  max-width: 20rem;
}

@media (min-width: 768px) {
  .DataSets select {
    max-width: none;
  }
}
