.DataSetsModal ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.DataSetsModal li {
  display: flex;
  margin-bottom: 1.4rem;
  justify-content: space-between;
}

.DataSetsModal input[type="text"] {
  border: 0.1rem solid var(--space);
  border-right: 0;
  height: 2.8rem;
  padding: 0.2rem 0.7rem 0;
  flex-grow: 1;
}

.DataSetsModal .default-dataset input[type="text"] {
  border-right: 0.1rem solid var(--space);
}

.DataSetsModal li button {
  font-size: 1.4rem;
  background-color: var(--space);
  color: var(--ship);
  flex-shrink: 0;
  height: 2.8rem;
  width: 2.8rem;
}

.DataSetModal__AddDataSet {
  background-color: var(--space);
  color: var(--ship);
  height: 2.8rem;
  line-height: 3rem;
  width: 100%;
}
