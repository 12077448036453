.CategoryList {
  animation: FadeIn 1 250ms linear;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .CategoryList {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 2.8rem;
    -webkit-overflow-scrolling: touch;
  }
}
