.DetailDay {
  height: 100%;
  position: relative;
}

.DetailDay__Header {
  display: flex;
  flex-direction: column;
  font-variant: small-caps;
  justify-content: space-between;
  letter-spacing: 0.1rem;
  padding: 2.8rem;
}

.DetailDay__Notes {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  margin: 2.8rem 0 0 0;
  padding: 0 2.8rem;
  padding: 0;
  width: 100%;
}

.DetailDay__Notes--open {
  height: 20rem;
}

.DetailDay__Notes button svg {
  transition: transform 300ms ease-in-out;
}

.DetailDay__Notes--open button svg {
  transform: rotate(180deg);
}

.DetailDay__NotesHeader {
  background-color: var(--ship);
  color: var(--space);
  display: flex;
  flex-shrink: 0;
  height: 2.8rem;
  justify-content: space-between;
  line-height: 3rem;
}

.DetailDay__Notes label {
  font-variant: small-caps;
  display: inline-block;
}

.DetailDay__Notes label span:first-child {
  border-left: 1px solid var(--space);
  display: inline-block;
  height: 2.8rem;
  text-align: center;
  width: 2.8rem;
}

.DetailDay__Notes button {
  background-color: var(--ship);
  border-left: 1px solid var(--space);
  color: var(--space);
  height: 2.8rem;
  width: 2.8rem;
}

.DetailDay__NotesText {
  border-bottom: 0.7rem solid var(--ship);
  flex-grow: 1;
}

.DetailDay__Notes textarea {
  border: none;
  background-color: var(--space);
  color: var(--ui);
  font-size: 1.4rem;
  height: 100%;
  line-height: 1.4;
  padding: 0.8rem 1rem;
  resize: none;
  width: 100%;
}

@media (min-width: 768px) {
  .DetailDay {
    display: flex;
    flex-direction: column;
  }

  .DetailDay__Notes {
    border-bottom: none;
    margin-top: 0;
  }

  .DetailDay__Notes,
  .DetailDay__Header {
    flex-shrink: 0;
  }

  .DetailDay__NotesText {
    border-bottom: none;
  }

  .DetailDay__Notes textarea {
    height: 100%;
  }
}
