.EditableCategory {
  position: relative;
  margin-bottom: 2.8rem;
}

.EditableCategory__Header {
  background-color: var(--ship);
  border-bottom: 1px solid var(--space);
  color: var(--space);
  display: flex;
  justify-content: space-between;
}

.EditableCategory__Info {
  display: flex;
  flex-grow: 1;
}

.EditableCategory__Info input {
  background-color: var(--space);
  border: none;
  color: var(--ship);
  flex-grow: 1;
  font-size: 1.4rem;
  font-variant: small-caps;
  height: 2.2rem;
  letter-spacing: 0.1rem;
  margin-top: 0.3rem;
  padding: 0.1rem 0.7rem 0;
  width: 10rem;
}

.EditableCategory__Info button,
.EditableCategory__Controls button {
  background-color: var(--ship);
  border: none;
  flex-shrink: 0;
  padding: 0;
  height: 2.8rem;
  width: 2.8rem;
}

.EditableCategory__Empty {
  background-color: var(--ship);
  color: var(--space);
  font-style: italic;
  font-size: 1.2rem;
  height: 2.8rem;
  left: 0;
  line-height: 2.9rem;
  opacity: 0.3;
  position: absolute;
  text-align: center;
  top: 2.8rem;
  user-select: none;
  width: 100%;
}

.EditableCategory__AddTracker button {
  width: 100%;
}
