.LoaderSmall {
  display: flex;
  font-size: 1.4rem;
  font-variant: small-caps;
  height: 2.8rem;
  line-height: 2.9rem;
}

.LoaderSmall > div:first-child {
  align-items: center;
  display: flex;
  height: 2.8rem;
  justify-content: center;
  width: 2.8rem;
}

.LoaderSmall svg {
  animation: Spin infinite 1s linear;
  width: 1.6rem;
}
