.modal-content {
  color: var(--space);
  font-size: 1.4rem;
  padding: 2.4rem 2.8rem 2.8rem;
}

.modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.1rem;
}

.modal-content h2 {
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  margin: 0 2.8rem 0 0;
}

.modal-close {
  background-color: var(--space);
  border: 0;
  color: var(--ship);
  font-size: 1.2rem;
  font-variant: small-caps;
  height: 2.8rem;
  letter-spacing: 0.1rem;
  line-height: 2.9rem;
  padding: 0 0.7rem;
}

.modal-close:hover {
  background-color: var(--ui);
}

.modal-close:focus {
  background-color: var(--ui-alt);
  outline: 0;
}

/* Forms */
.modal-form {
  width: 32rem;
}

.modal-form form > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-form form > ul > li:not(:last-child) {
  margin-bottom: 2.1rem;
}

.modal-form p {
  line-height: 1.5;
}

.modal-form label {
  display: block;
  font-size: 1.4rem;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  margin-bottom: 0.7rem;
}

.modal-form input {
  border: 1px solid var(--space);
  height: 2.8rem;
  line-height: 2.9rem;
  padding: 0 0.7rem;
  width: 100%;
}

.modal-form textarea {
  line-height: 1.5;
  padding: 0.7rem;
  resize: vertical;
  width: 100%;
}

.modal-form__error {
  color: var(--danger);
  line-height: 1.5;
  margin-top: 0.7rem;
}

.modal-form .field-error label {
  color: var(--danger);
}

.modal-form .field-error input,
.modal-form .field-error textarea {
  border: 1px solid var(--danger);
}

.modal-form button[type="submit"] {
  background-color: var(--ui-dark);
  color: var(--ship);
  font-variant: small-caps;
  height: 4.2rem;
  line-height: 4.4rem;
  font-size: 1.6rem;
  padding: 0 1.4rem;
  width: 100%;
}

.modal-form button[type="submit"]:hover {
  background-color: var(--ui);
}

.modal-form button[type="submit"]:focus {
  background-color: var(--ui-alt);
  outline: 0;
}
