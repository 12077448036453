.Header {
  background-color: var(--ship);
  color: var(--space);
  display: flex;
  justify-content: space-between;
  font-variant: small-caps;
  font-size: 1.4rem;
  height: 5.6rem;
  letter-spacing: 0.1rem;
  padding: 0 1.4rem;
  flex-shrink: 0;
}

.Header > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Header p,
.Header h1,
.Header h2 {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 6rem;
  margin: 0;
}

.Header button:focus,
.Header select:focus,
.Header input:focus {
  background-color: var(--ui-alt);
  color: var(--space);
  outline: 0;
}

.Header button {
  background-color: var(--ship-ui);
  color: var(--ship);
  font-variant: small-caps;
  height: 2.8rem;
  padding: 0 1rem;
}

.Header button.icon-button {
  padding: 0;
  width: 2.8rem;
}

.Header button:hover:not(:disabled) {
  background-color: var(--ui);
  color: var(--space);
}

.Header button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Header select {
  appearance: none;
  background: var(--ship-ui);
  border: none;
  color: var(--ship);
  height: 2.8rem;
  padding: 0 0.5rem;
}

.Header select:hover:not(:disabled) {
  background-color: var(--ui);
  color: var(--space);
  cursor: pointer;
}

.Header option {
  background-color: var(--ship-ui);
  color: var(--ship);
}

.Header select:focus option {
  outline: 0;
}

.Header input {
  border: 0.1rem solid var(--ship-ui);
  height: 2.8rem;
  padding: 0 1rem;
}

.Header__Title,
.Header__Controls {
  display: flex;
}

.Header__Title h1 {
  font-size: 2rem;
  font-variant: normal;
  line-height: 4.5rem;
  text-transform: lowercase;
}

.Header__Title h1 svg:first-child {
  animation: RainbowColor 120s linear 0s infinite;
  height: 3.4rem;
  margin-top: 1rem;
  margin-right: 0.4rem;
  vertical-align: top;
}

.Header__Title h1 svg:last-child {
  display: none;
}

.Header__Controls label {
  white-space: nowrap;
}

.Header__Controls > div:not(:first-child) {
  margin-left: 2.8rem;
}

.Header__Controls .UserLogout {
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .Header__Title h1 svg:last-child {
    animation: RainbowColor 110s linear 0s infinite;
    display: inline-block;
    height: 2.8rem;
    margin-top: 1.3rem;
    vertical-align: top;
  }
}
