@keyframes RainbowColor {
  0% {
    color: #0af;
  }
  15% {
    color: #0af;
  }
  25% {
    color: rgb(0, 207, 111);
  }
  35% {
    color: rgb(200, 255, 0);
  }
  45% {
    color: rgb(235, 199, 0);
  }
  55% {
    color: rgb(219, 51, 0);
  }
  65% {
    color: rgb(255, 0, 128);
  }
  75% {
    color: rgb(109, 0, 252);
  }
  90% {
    color: #0af;
  }
  100% {
    color: #0af;
  }
}

@keyframes Spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animation-rainbow {
  animation: RainbowColor 45s linear 0s infinite;
}
