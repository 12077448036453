.SignedOut {
  background: url("../../../images/stars3.png") var(--space);
  background-attachment: fixed;
  font-size: 1.6rem;
  min-height: 100vh;
}

.SignedOut p {
  font-family: "Montserrat", sans-serif;
}

.SignedOut__Header {
  background-color: var(--ship);
  color: var(--space);
}

.SignedOut__Header > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 96rem;
  margin: 0 auto;
  padding: 2.8rem 0 4.2rem;
}

.SignedOut__Header h1 {
  margin: 0;
  overflow: hidden;
  display: flex;
}

.SignedOut__Header h1 svg:first-child {
  animation: RainbowColor 60s linear 0s infinite;
  margin-right: 0.7rem;
  width: 10rem;
}

.SignedOut__Header h1 svg:last-child {
  animation: RainbowColor 55s linear 0s infinite;
  width: 20rem;
}

.SignedOut__Pitch {
  margin: 6.2rem auto;
  max-width: 96rem;
  padding: 0 2.8rem;
}

.SignedOut__Pitch > svg {
  color: var(--ui);
  display: block;
  margin: 0 auto 2.8rem;
  width: 10rem;
}

.SignedOut__Copy {
  margin-bottom: 5.6rem;
}

.SignedOut__Copy h2 {
  font-size: 3.6rem;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  margin: 0 0 4.2rem 0;
  text-align: center;
}

.SignedOut__Copy ul {
  font-family: "Montserrat", sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
}

.SignedOut__Copy li {
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 0 0 2.8rem;
  padding: 0;
  display: flex;
  align-items: center;
}

.SignedOut__Copy li svg {
  width: 100%;
}

.SignedOut__Copy li > div:first-child {
  flex-shrink: 0;
  margin-right: 1.4em;
  width: 8rem;
}

.SignedOut__Copy li:last-child {
  margin: 0;
}

.SignedOut__Examples {
  background-color: var(--ship);
  color: var(--space);
}

.SignedOut__Details {
  max-width: 84rem;
  margin: 5.6rem auto;
  padding: 0 2.8rem;
}

.SignedOut__Notes h2 {
  margin: 0;
}

.SignedOut__Notes p {
  margin: 0;
}

.Footer {
  background-color: var(--ship);
  color: var(--space);
  padding: 2.8rem 0;
}

.Footer p {
  margin: 0 auto;
  padding: 0 2.8rem;
  max-width: 96rem;
}

@media (min-width: 768px) {
  .SignedOut__Header > div {
    align-items: stretch;
    flex-direction: row;
    padding: 0.7rem 2.8rem;
  }

  .SignedOut__Header h1 {
    height: 8.4rem;
  }

  .SignedOut__Header h1 svg:first-child {
    width: 6.6rem;
  }

  .SignedOut__Header h1 svg:last-child {
    width: 14.6rem;
  }

  .SignedOut__Pitch {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 11.2rem auto;
    max-width: 96rem;
  }

  .SignedOut__Pitch > div:first-child {
    width: calc(60% - 1.4rem);
  }

  .SignedOut__Pitch > div:last-child {
    width: calc(40% - 1.4rem);
  }

  .SignedOut__Copy {
    margin-bottom: 0;
  }

  .SignedOut__Copy h2 {
    font-size: 2.8rem;
    margin: 0 0 2.4rem 0;
    text-align: left;
  }

  .SignedOut__SignUp {
    flex-shrink: 0;
  }
}
