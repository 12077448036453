.Timeline {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Timeline__Calendar {
  animation: FadeIn 1 500ms linear;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 4rem);
  justify-content: center;
  padding: 2.8rem;
  width: 100%;
}

.Timeline__Calendar > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.Timeline__DOWs {
  display: "flex";
  flex-direction: "column";
  width: 4.8rem;
  flex-shrink: 1;
}

.Timeline__DOW {
  background-color: #111;
  border: 1px solid transparent;
  text-align: right;
  padding: 0 1.4rem;
  height: 10rem;
  width: 100%;
}

.Timeline__DOW abbr {
  line-height: 10.6rem;
  font-size: 2.8rem;
  font-weight: bold;
  text-decoration: none;
}

@media (min-width: 768px) {
  .Timeline__Calendar {
    overflow-y: auto;
    padding: 0 2.8rem;
    -webkit-overflow-scrolling: touch;
  }

  .Timeline__Calendar > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-height: calc(100% - 4rem);
  }
}
