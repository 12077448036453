.ChartDisplay {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ChartDisplay__Chart {
  color: white;
  flex-grow: 1;
  height: 100vh;
  padding: 2.8rem;
}

.ChartDisplay .react-chart {
  color: var(--ship);
}

.ChartDisplayTooltip {
  background-color: var(--ship);
  border: 0.4rem solid #fff;
  color: var(--space);
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

.ChartDisplayTooltip > div {
  padding: 0.7rem;
}

.ChartDisplayTooltip__Label {
  border-bottom: 0.4rem solid #fff;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
}

.ChartDisplayTooltip__Date {
  font-style: italic;
  font-size: 1rem;
  text-align: center;
}

.ChartDisplayTooltip__Data {
  text-align: center;
}

@media (min-width: 768px) {
  .ChartDisplay__Chart {
    height: calc(100vh - 18rem);
  }
}
