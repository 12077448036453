.Tracker {
  background-color: var(--space);
  color: var(--ship);
  display: flex;
  justify-content: space-between;
}

.Tracker.Tracker--filtered {
  opacity: 0.6;
}

.Tracker button {
  background-color: transparent;
  border: none;
  color: var(--ship);
  flex-shrink: 0;
  height: 2.8rem;
  padding: 0;
  width: 2.8rem;
}

.Tracker__Info {
  display: flex;
  height: 2.8rem;
  line-height: 3rem;
  max-width: calc(100% - 9rem);
}

.Tracker__Emoji {
  flex-shrink: 0;
}

.Tracker__Emoji > span {
  position: relative;
  top: -0.1rem;
}

.Tracker__Label {
  font-size: 1.4rem;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  overflow: hidden;
  padding-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Tracker__Dailytotal {
  display: flex;
  flex-shrink: 0;
  font-size: 1.4rem;
  height: 2.8rem;
  line-height: 3rem;
  width: 8.4rem;
}

.Tracker__Dailytotal button:disabled {
  opacity: 0.25;
}

.Tracker__Dailytotal span {
  display: inline-block;
  text-align: center;
  width: 2.8rem;
}
