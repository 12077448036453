.ForgotPassword__Sending {
  display: flex;
  justify-content: center;
}

.ForgotPassword__Sending .LoaderMedium {
  margin-top: 1.4rem;
}

.ForgotPassword__Sent p {
  margin: 0;
}
