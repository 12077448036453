.verified {
  color: var(--ui);
}

.Settings {
  animation: FadeIn 1 250ms linear;
  font-variant: small-caps;
  padding: 2.8rem;
}

.Settings > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Settings > h2:first-child {
  margin-top: 0;
}

.Settings__Label {
  border-bottom: 0.1rem solid var(--ship);
  display: flex;
  height: 2.8rem;
  line-height: 3rem;
}

.Settings__Label span:first-child {
  flex-shrink: 0;
  width: 2.8rem;
  text-align: center;
}

.Settings__Label span:last-child {
  padding: 0 0.7rem;
}

.Settings__Label svg {
  position: relative;
  top: -0.1rem;
}

.Settings__Value {
  height: 2.8rem;
  line-height: 3rem;
  font-size: 1.4rem;
}

.Settings__Details > li {
  margin-bottom: 2.8rem;
}

.Settings__EmailVerify {
  display: flex;
  justify-content: space-between;
}

.Settings__EmailVerify > div:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Settings__EmailVerify > div:last-child {
  flex-shrink: 0;
}

.Settings__EmailVerify button {
  background-color: transparent;
  color: var(--ui);
  font-size: 1.4rem;
  font-variant: small-caps;
  height: 2.8rem;
  line-height: 3rem;
}

.Settings__EmailVerify button:hover {
  color: var(--ui-dark);
}

.Settings__EmailVerify button:focus {
  color: var(--ui-alt);
  outline: none;
}

.Settings__EmailVerify > div:first-child {
  margin-right: 1.4rem;
}

.Settings__Actions li:not(:last-child) {
  margin-bottom: 0.1rem;
}

.Settings__Actions button {
  background-color: var(--ship);
  color: var(--space);
  display: flex;
  font-variant: small-caps;
  height: 2.8rem;
  justify-content: flex-start;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  text-align: left;
  width: 100%;
}

.Settings__Actions button > div:first-child {
  text-align: center;
  width: 2.8rem;
}

.Settings__Actions button > div:first-child svg {
  position: relative;
  top: -0.1rem;
}

.Settings__Actions button > div:last-child {
  padding: 0 0.7rem;
}
