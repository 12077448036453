.TrackerModal label {
  display: block;
  margin-bottom: 0.7rem;
}

.TrackerModal > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.TrackerModal > ul > li:not(:last-child) {
  margin-bottom: 1.4rem;
}

.TrackerModal__Preview {
  background-color: var(--space);
  color: var(--ship);
  display: flex;
  height: 2.8rem;
  line-height: 2.9rem;
  max-width: 25.2rem;
  overflow: hidden;
}

.TrackerModal__Preview .sm-emoji {
  flex-shrink: 0;
}

.TrackerModal__Label {
  font-size: 1.4rem;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  padding-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TrackerModal__EmojiPicker .emoji-mart-preview {
  display: none;
}

.TrackerModal__EmojiPicker .emoji-mart-scroll {
  height: 12rem;
}

.TrackerModal__EmojiPicker
  .emoji-mart-category
  .emoji-mart-emoji:hover::before {
  display: none;
}

.TrackerModal__EmojiPicker .emoji-mart .emoji-mart-emoji {
  height: 2.8rem;
  padding: 0;
  width: 2.8rem;
}

.TrackerModal__EmojiPicker .emoji-mart .emoji-mart-emoji span {
  cursor: pointer;
  left: 0.2rem;
  position: absolute;
  top: 0.2rem;
}

.TrackerModal__Points {
  display: flex;
}

.TrackerModal__Points button {
  background-color: var(--space);
  color: var(--ship);
  height: 2.8rem;
  width: 2.8rem;
}

.TrackerModal__Points span {
  display: block;
  height: 2.8rem;
  line-height: 3rem;
  padding: 0 0.7rem;
  text-align: center;
}

.TrackerModal__Points span svg {
  margin-right: 0.7rem;
  position: relative;
  top: -0.2rem;
}
