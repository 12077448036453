.Sidebar {
  background-color: var(--space);
  height: 100%;
  position: relative;
  width: 100%;
}

.Sidebar__View {
  height: calc(100% - 4rem);
}

.Sidebar__Collapse {
  display: none;
  height: 2.8rem;
  left: -2.8rem;
  position: absolute;
  top: calc(50% - 3.4rem);
  transition: left 200ms;
  width: 2.8rem;
}

.Sidebar__Collapse button {
  background-color: var(--ship);
  border-top: 1px solid var(--space);
  border-bottom: 1px solid var(--space);
  height: 2.8rem;
  width: 2.8rem;
}

@media (min-width: 768px) {
  .Sidebar {
    border-left: 2.8rem solid var(--ship);
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 35rem;
  }

  .Sidebar .Tabs {
    flex-shrink: 0;
  }

  .Sidebar__View {
    flex-grow: 1;
  }

  .Sidebar.Sidebar--collapsed {
    width: 0.5rem;
  }

  .Sidebar__Collapse {
    display: block;
  }

  .Sidebar__Collapse button svg {
    transition: transform 300ms ease-in-out;
  }

  .Sidebar--collapsed .Sidebar__Collapse button svg {
    transform: rotate(180deg);
  }
}

@media (min-width: 1024px) {
  .Sidebar {
    width: 40rem;
  }

  .Sidebar.Sidebar--collapsed {
    width: 0.5rem;
  }
}
