.DisplayControls {
  align-items: center;
  background-color: #222;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 1.4rem;
  font-variant: small-caps;
  position: relative;
}

.DisplayControls div[role="group"] {
  display: flex;
  padding: 0.7rem 0 0 0;
}

.DisplayControls .DisplayControls__Type[role="group"] {
  display: none;
}

.DisplayControls input[type="radio"] {
  background-color: var(--ship-ui);
  height: 2.8rem;
  padding: 0 0.7rem;
  line-height: 2.8rem;
}

.Toggle {
  background-color: transparent;
}

.Toggle label {
  height: 2.8rem;
  line-height: 2.8rem;
}

.Toggle__Radio {
  background-color: var(--ship-ui);
  display: flex;
  padding: 0 0.7em;
}

.Toggle__Radio:not(:last-child) {
  border-right: 1px solid #222;
}

.DisplayControls .Toggle__Radio label {
  background-color: var(--ship-ui);
  color: var(--ship);
  cursor: pointer;
  padding: 0 0.7rem 0 0;
}

.DisplayControls .Toggle__Radio input {
  cursor: pointer;
}

.DisplayControls .Checkbox {
  background-color: var(--ship-ui);
  display: flex;
  padding: 0 0.7rem;
}

.DisplayControls .Checkbox label {
  background-color: transparent;
  padding: 0 0.7rem 0 0;
}

.DisplayControls .Checkbox input {
  height: 2.8rem;
  line-height: 2.8rem;
}

.DisplayControls select {
  appearance: none;
  background-color: var(--ship-ui);
  border: none;
  color: var(--ship);
  cursor: pointer;
  font-size: 1.4rem;
  font-variant: small-caps;
  height: 2.8rem;
  letter-spacing: 0.1rem;
  line-height: 2.8rem;
  padding: 0 0.7rem;
  position: relative;
  text-align: center;
  z-index: 1;
}

.DisplayControls button + select {
  border-left: 1px solid #222;
}

.DisplayControls select:not(:last-child) {
  border-right: 1px solid #222;
}

.DisplayControls select:focus {
  background-color: var(--ui-alt);
  color: var(--space);
  position: relative;
  z-index: 2;
}

.DisplayControls select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--space);
}

.DisplayControls select:hover {
  color: var(--space);
  background-color: var(--ui);
  position: relative;
}

.DisplayControls option {
  background-color: var(--ship-ui);
  color: var(--ship);
}

.DisplayControls select:focus option {
  outline: 0;
}

.DisplayControls option[disabled] {
  color: rgba(255, 255, 255, 0.5);
}

.DisplayControls button {
  background-color: var(--ship-ui);
  border: none;
  display: block;
  color: var(--ship);
  height: 2.8rem;
  line-height: 2.7rem;
  overflow: hidden;
  width: 2.8rem;
}

.DisplayControls button:focus {
  background-color: var(--ui-alt);
  color: var(--space);
  position: relative;
  z-index: 2;
}

.DisplayControls button:not(:disabled):hover {
  background-color: var(--ui);
  color: var(--space);
  position: relative;
  z-index: 2;
}

.DisplayControls button[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

.DisplayControls label,
.DisplayControls__Label {
  background-color: var(--ship);
  color: var(--space);
  letter-spacing: 0.1rem;
  line-height: 2.8rem;
  padding: 0 1rem;
  white-space: nowrap;
}

.DisplayPicker {
  display: flex;
}

.DisplayControls__Custom {
  padding-bottom: 0.7rem;
}

@media (min-width: 768px) {
  .Display {
    width: calc(100% - 35rem);
    height: 100%;
  }

  .DisplayControls {
    display: block;
  }

  .Display.Display--sidebar-collapsed {
    width: 100%;
  }

  .DisplayControls {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .DisplayControls .DisplayControls__Type[role="group"] {
    align-self: flex-start;
    background-color: var(--ship);
    border-bottom-right-radius: 0.1rem;
    display: flex;
    padding: 0.7rem 1.4rem;
    position: relative;
  }

  .DisplayControls .DisplayControls__Type .DisplayPicker {
    display: flex;
    flex-grow: 0;
    width: auto;
  }

  .DisplayControls .DisplayControls__Type label {
    color: transparent;
    overflow: hidden;
    padding: 0;
    width: 0;
  }

  .DisplayControls__Custom {
    display: flex;
    flex-basis: 30rem;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
  }

  .DisplayControls__Custom > div[role="group"] {
    justify-content: center;
    margin: 0.7rem 0 0 0;
    padding: 0 0.7rem;
  }
}

@media (min-width: 1024px) {
  .Display {
    width: calc(100% - 40rem);
  }

  .DisplayControls .DisplayControls__Type label {
    background-color: var(--ship);
    color: var(--space);
    padding: 0 1rem 0 0;
    width: auto;
  }
}
