.SignInForm {
  font-size: 1.4rem;
  margin: 2.8rem 0 0;
  min-height: 100%;
  position: relative;
}

.SignInForm ul {
  align-items: center;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.SignInForm li {
  display: flex;
  flex-direction: column;
}

.SignInForm li:not(:last-child) {
  margin-bottom: 1.4rem;
}

.SignInForm__Forgot {
  background-color: transparent;
  border: 0;
  color: var(--ui-dark);
  display: block;
  margin: 0.7rem 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
}

.SignInForm__Forgot:hover {
  color: var(--ui);
}

.SignInForm label {
  display: block;
  height: 2.8rem;
  line-height: 2.9rem;
}

.SignInForm input[type="text"],
.SignInForm input[type="password"] {
  border: 0.1rem solid var(--space);
  border-radius: 0.1rem;
  background-color: var(--ship);
  color: var(--space);
  height: 3.5rem;
  font-size: 1.6rem;
  padding: 0 1.4rem;
  width: 30rem;
}

.SignInForm .field-error label {
  color: var(--danger);
}

.SignInForm .field-error input[type="text"],
.SignInForm .field-error input[type="password"] {
  border: 0.1rem solid var(--danger);
}

.SignInForm button[type="submit"] {
  border: 0;
  border-radius: 0.1rem;
  background-color: var(--ui-dark);
  color: var(--ship);
  font-size: 2rem;
  font-variant: small-caps;
  height: 4.2rem;
  letter-spacing: 0.1rem;
  line-height: 4.3rem;
  padding: 0 1.4rem;
  width: 30rem;
}

.SignInForm button[type="submit"]:hover {
  background-color: var(--ui);
}

.SignInForm__Errors {
  align-items: center;
  background-color: var(--danger-alt);
  border: 7px solid var(--ship);
  color: var(--ship);
  display: flex;
  justify-content: space-between;
  left: -0.7rem;
  padding: 1.4rem;
  position: absolute;
  top: calc(100%);
  width: calc(100% + 1.4rem);
}

.SignInForm__Errors ul {
  margin-right: 2.8rem;
}

.SignInForm__Errors > div {
  flex-shrink: 0;
}

.SignInForm__Errors button {
  background-color: var(--ship);
  color: var(--space);
  height: 2.8rem;
  line-height: 2.9rem;
  padding: 0 1.4rem;
}

.SignInForm__Errors button:hover {
  background-color: var(--space);
  color: var(--ship);
}

@media (min-width: 768px) {
  .SignInForm {
    margin: 0;
  }

  .SignInForm ul {
    align-items: stretch;
    flex-direction: row;
  }

  .SignInForm li:not(:last-child) {
    margin-right: 1.4rem;
    margin-bottom: 0;
  }

  .SignInForm label {
    font-size: 1.2rem;
  }

  .SignInForm input[type="text"],
  .SignInForm input[type="password"] {
    font-size: 1.4rem;
    height: 2.8rem;
    padding: 0 0.7rem;
    width: 16rem;
  }

  .SignInForm__Forgot {
    font-size: 1rem;
    margin-top: 0.7rem;
  }

  .SignInForm button[type="submit"] {
    font-size: 1.4rem;
    height: 2.8rem;
    line-height: 2.9rem;
    margin-top: 2.8rem;
    width: auto;
  }
}
