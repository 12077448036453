.Tabs {
  background-color: var(--ship);
  border-bottom: 3px solid var(--ship);
  display: flex;
  height: 4rem;
  text-transform: uppercase;
}

.Tabs button {
  background-color: var(--ship-ui);
  border: none;
  border-bottom: 3px solid var(--ship);
  border-right: 3px solid var(--ship);
  color: var(--ship);
  display: inline-block;
  height: 4rem;
  padding: 0;
  position: relative;
  width: 4rem;
}

.Tabs button:hover {
  color: var(--ui);
  background-color: var(--space);
}

.Tabs button:focus {
  background-color: var(--ui-alt);
  color: var(--space);
  outline: none;
}

.Tabs button:hover {
  cursor: pointer;
}

.Tabs button.is-active {
  background-color: var(--space);
}

.Tabs button:focus.is-active {
  background-color: var(--space);
  color: var(--ui-alt);
}

.Tabs button:focus.is-active:after {
  background-color: var(--space);
}

.Tabs button.is-active:after {
  background-color: var(--space);
  bottom: -0.3rem;
  content: "";
  display: block;
  height: 0.5rem;
  position: absolute;
  width: 100%;
}

@media (max-width: 767px) {
  .Tabs .Tab--Display.is-active:focus,
  .Tabs .Tab--Display.is-active:active,
  .Tabs .Tab--Display.is-active {
    background-color: #222;
  }

  .Tabs .Tab--Display.is-active:focus:after,
  .Tabs .Tab--Display.is-active:active:after,
  .Tabs .Tab--Display.is-active:after {
    background-color: #222;
  }
}
