.Edit {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.EditableCategoryList {
  margin-top: 2.8rem;
}

.EditableCategoryList > ul {
  list-style: none;
  margin: 0;
  padding: 0 2.8rem;
}

.EditableCategoryList__Controls {
  padding: 0 2.8rem;
}

.EditableCategoryList {
  animation: FadeIn 1 250ms linear;
  color: var(--ship);
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.EditableCategoryList .button {
  background-color: var(--ship);
  border-top: 1px solid var(--space);
  color: var(--space);
  font-size: 1.4rem;
  padding-right: 1rem;
}

.EditableCategoryList .button span:first-child {
  display: inline-block;
  font-size: 1.4rem;
  height: 2.8rem;
  line-height: 2.8rem;
  width: 2.8rem;
}
