.Details {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Details h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 2.8rem;
  margin: 0 0 2.8rem;
  padding: 0 0 2.1rem;
  text-align: center;
}

.Details > li {
  background-color: var(--ship);
  color: var(--space);
  padding: 2.8rem;
  margin-bottom: 5.6rem;
  overflow: hidden;
}

.Details p {
  line-height: 1.5;
  margin: 0 0 2.8rem;
}

.Details__Image {
  margin-bottom: 5.6rem;
  text-align: center;
}

@media (min-width: 768px) {
  .Detail {
    align-items: center;
    display: flex;
  }

  .Detail:not(:last-child) {
    margin-bottom: 5.6rem;
  }

  .Detail--reverse {
    flex-direction: row-reverse;
  }

  .Details__Image {
    margin-bottom: 0;
  }

  .Details__Image img {
    display: block;
  }

  .Detail > div p {
    margin: 0 auto;
    max-width: 34rem;
  }

  .Detail > div:first-child {
    flex-grow: 1;
    padding-right: 2.8rem;
  }

  .Detail.Detail--reverse > div:first-child {
    padding-right: 0;
  }
  .Detail.Detail--reverse > div:last-child {
    padding-right: 2.8rem;
  }
}
