.Streak {
  font-size: 1.4rem;
  height: 100%;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
}

.Streak .tracker-emoji {
  display: block;
  height: 2.8rem;
  line-height: 3rem;
  overflow: hidden;
  text-align: center;
  width: 2.8rem;
}

.Streak .DisplayControls {
  flex-shrink: 0;
}

.Streak__Data {
  animation: FadeIn 1 500ms linear;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.Streak__Table {
  display: flex;
  margin: 0 2.8rem 2.8rem;
  padding-top: 2.8rem;
}

.Streak__Sidebar {
  padding-top: 5.6rem;
  width: 16rem;
}

.Display--compact .Streak__Sidebar {
  width: 2.9rem;
}

.Streak__Category {
  font-variant: small-caps;
  letter-spacing: 1px;
}

.Streak__Tracker {
  display: flex;
  height: 2.8rem;
  justify-content: flex-start;
}

.Streak__Tracker > span:first-child {
  flex-shrink: 0;
  margin-right: 1rem;
}

.Streak__Tracker > span:last-child {
  flex-grow: 1;
  line-height: 3rem;
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Display--compact .Streak__Tracker > span:last-child {
  display: none;
}

.Streak__Days {
  display: flex;
}

.Streak__Day {
  position: relative;
  width: 2.8rem;
}

.Streak__DayCategories {
  animation: FadeIn 1 500ms linear;
  background-color: var(--space);
}

.Streak__Day:hover {
  cursor: pointer;
  z-index: 1;
}

.Streak__Day.Streak__Day--selected {
  z-index: 1;
}

.Streak__Day.Streak__Day--selected .Streak__DayCategories {
  outline: 2px solid var(--ship);
}

.Streak__Day:not(.Streak__Day--selected):hover .Streak__DayCategories {
  outline: 2px solid var(--ui);
}

.Streak__Day:not(.Streak__Day--selected):focus .Streak__DayCategories {
  outline: 2px solid var(--ui-alt);
}

.Streak__Day:not(.Streak__Day--selected):hover .Streak__DayDay {
  border-bottom: 1px solid var(--ui);
  background-color: var(--ui);
  color: var(--space);
}

.Streak__Day:not(.Streak__Day--selected):focus .Streak__DayDay {
  border-bottom: 1px solid var(--ui-alt);
  background-color: var(--ui-alt);
  color: var(--space);
}

.Streak__Day.Streak__Day--selected .Streak__DayDay {
  background-color: var(--ship);
  color: var(--space);
  border-bottom: 1px solid var(--ship);
}

.Streak__Day:focus {
  outline: none;
  z-index: 1;
}

.Streak__DayDate {
  font-variant: small-caps;
  padding-top: 2.8rem;
  height: 2.8rem;
}

.Streak__DayMonth {
  height: 2.8rem;
  left: 0.4rem;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.Streak__DayMonth .emoji {
  display: inline-block;
  margin-right: 0.4rem;
}

.Streak__DayDay {
  border-bottom: 1px solid var(--ship-ui);
  height: 2.8rem;
  line-height: 2.8rem;
  text-align: center;
}

.Streak__DayDate > span {
  height: 2.8rem;
}

.Streak__DayCategory > span {
  display: none;
  color: var(--ship);
  background-color: #111;
  height: 2.8rem;
  width: 100%;
}

.Streak__Day:not(:hover):not(:focus):not(.Streak__Day--selected).Streak__Day--weekend
  .Streak__DayDay,
.Streak__Day.Streak__Day--weekend .Streak__DayCategory,
.Streak__Day.Streak__Day--weekend .Streak__DayTotal {
  background-color: #111;
}

.Streak__DayTotal,
.Streak__DayCategory {
  background-color: var(--space);
}

.Streak__DayTracker {
  height: 2.8rem;
  position: relative;
}

.Streak__DayTracker .tracker-total {
  font-size: 1.2rem;
  display: none;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1.4rem;
  line-height: 1;
  text-shadow: var(--space) 1px 1px 0;
}

.Streak__DayTotal {
  border-top: 1px solid var(--ship-ui);
  overflow: hidden;
  text-align: center;
  width: 2.8rem;
}

.Streak__ScoreLabel {
  border-top: 1px solid var(--ship-ui);
  color: var(--ship);
  font-variant: small-caps;
  height: 2.8rem;
  text-align: center;
}

.Streak__Day.Streak__Day--selected .Streak__DayTotal {
  background-color: var(--ship);
  color: var(--space);
  border-top: 1px solid var(--ship);
}

.Streak__Day:not(.Streak__Day--selected):hover .Streak__DayTotal {
  border-top: 1px solid var(--ui);
  background-color: var(--ui);
  color: var(--space);
}

.Streak__Day:not(.Streak__Day--selected):focus .Streak__DayTotal {
  border-top: 1px solid var(--ui-alt);
  background-color: var(--ui-alt);
  color: var(--space);
}

@media (min-width: 768px) {
  .Streak__ScoreLabel {
    font-variant: small-caps;
    padding: 0 1rem 0 1rem;
    text-align: right;
  }
}
