.EditableTracker {
  position: relative;
  width: 100%;
}

.EditableTracker__Fields {
  display: flex;
  justify-content: space-between;
  height: 2.8rem;
}

.EditableTracker__Fields > button,
.EditableTracker__Fields > div > button {
  background-color: transparent;
  height: 2.8rem;
  width: 2.8rem;
  flex-shrink: 0;
}

.EditableTracker__Emoji {
  width: 2.8rem;
}

.EditableTracker__Label {
  flex-grow: 1;
  width: 0;
}

.EditableTracker__Label input {
  background-color: var(--ship);
  border: none;
  color: var(--space);
  flex-grow: 1;
  font-size: 1.4rem;
  font-variant: small-caps;
  height: 2.2rem;
  letter-spacing: 0.1rem;
  margin: 0.3rem 0;
  padding: 0.2rem 0.7rem 0;
  width: 100%;
}
